import React, {Component} from 'react'
import moment from 'moment';
import DefaultTemplate from "../templates/default"
import AOS from 'aos';
import 'aos/dist/aos.css';

class BlogSingle extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){
        AOS.init({
            once: true
        })
    }

    render() {
    return <DefaultTemplate>
        <div className="blog-single-page">
            <div className="frame">
                <div className="blog-wrraper">
                    { this.props.pageContext.featured_media ? (
                        <div className="blog-left"><img src={ this.props.pageContext.featured_media.source_url } alt="" /></div>
                        ) : null
                    }
                    <div className="blog-right">
                        <div className="date">{ moment(this.props.pageContext.date).format('DD MMMM YYYY')}</div>
                        <div className="blog-title" dangerouslySetInnerHTML={{__html: this.props.pageContext.title}} />
                        <div className="blog-content" dangerouslySetInnerHTML={{__html: this.props.pageContext.content}} />
                    </div>
                </div>
            </div>
        </div>
    </DefaultTemplate>
    }

}

export default BlogSingle
   